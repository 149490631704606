import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Home: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 17 18" {...props}>
      <path d="M0.275391 18V6L8.27539 0L16.2754 6V18H10.2754V11H6.27539V18H0.275391Z" fill="currentColor"/>
    </Icon>
  );
};
