import React, { useEffect, useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  HStack,
  Select,
  BoxProps,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import { validate } from "../../utils/validate";
import _ from "lodash";

export interface ChartaFieldProps extends BoxProps {
  field: any;
  register: any;
  getValues: any;
  setValue: any;
  validations: any;
  control: any;
  negotiation?: boolean;
  pathToDefaultValue?: string;
  objectWithDefaultValue?: any;
}

export const ChartaField: React.FC<ChartaFieldProps> = ({
  field,
  register,
  getValues,
  setValue,
  validations,
  control,
  negotiation,
  w,
  pathToDefaultValue,
  objectWithDefaultValue,
  ...props
}) => {
  let component;

  let type = field.component;

  if (negotiation) {
    if (type === "radio") {
      type = "select";
    }
  }

  const inputBorderColor = useColorModeValue(
    "rgba(27, 28, 49, 0.5)",
    "rgba(255, 255, 255, 0.75)"
  );

  const selectedRadioBorderColor = useColorModeValue(
    "linear-gradient(45deg, #8E30BA, #4B1495) 1",
    "linear-gradient(45deg, #FDE033, #EE4373) 1"
  );
  const radioBorderColor = useColorModeValue(
    "rgba(27, 28, 49, 0.75)",
    "brand.secondary1"
  );
  const selectedRadioColor = useColorModeValue("#1B1C31", "brand.primary");
  const radioColor = useColorModeValue("rgba(27, 28, 49, 0.75)", "brand.secondary1");

  useEffect(() => {
    if (pathToDefaultValue && objectWithDefaultValue) {
      if (_.has(objectWithDefaultValue, pathToDefaultValue)) {
        setValue(field.id, _.get(objectWithDefaultValue, pathToDefaultValue));
      }
    }
  }, []);

  switch (type) {
    case "input":
      component = (
        <InputGroup w={w}>
          {field.prefix && <InputLeftElement>{field.prefix}</InputLeftElement>}
          <Input
            type={field.type}
            placeholder={field.placeholder}
            {...register(field.id, {
              validate: () => validate(field.id, getValues(), validations),
            })}
            borderColor={inputBorderColor}
          />
          {field.postfix && (
            <InputRightElement>{field.postfix}</InputRightElement>
          )}
        </InputGroup>
      );
      break;
    case "radio":
      component = (
        <Controller
          name={field.id}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={onChange} value={value}>
              <HStack w={w}>
                {field.options.map((option: any) => (
                  <Box
                    key={option.label}
                    pt="4px"
                    pr="16px"
                    pb="4px"
                    pl="4px"
                  >
                    <Radio
                      value={option.value}
                      {...register(field.id, {
                        validate: () =>
                          validate(field.id, getValues(), validations),
                      })}
                    >
                      <Text
                        color={
                          option.value === value
                            ? selectedRadioColor
                            : radioColor
                        }
                      >
                        {option.label}
                      </Text>
                    </Radio>
                  </Box>
                ))}
              </HStack>
            </RadioGroup>
          )}
        />
      );
      break;
    case "select":
      component = (
        <Select
          w={w}
          placeholder={field.placeholder}
          {...register(field.id, {
            validate: () => validate(field.id, getValues(), validations),
          })}
          borderColor={inputBorderColor}
        >
          {field.options.map((opt: any) => (
            <option key={opt.label} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
      );
      break;
    default:
      component = <Box />;
      break;
  }
  return React.cloneElement(component);
};
