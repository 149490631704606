import { Box, IconProps } from "@chakra-ui/react";
import React from "react";
import { Clef } from "../icons/Clef";
import { Money } from "../icons/Money";
import { Logistics } from "../icons/Logistics";

export interface ChartaIconProps extends IconProps {
  name: string;
}

export const ChartaIcon: React.FC<ChartaIconProps> = ({ name, ...props }) => {
  switch (name) {
    case "money":
      return <Money {...props} />;
    case "clef":
      return <Clef {...props} />;
    case "logistics": 
      return <Logistics {...props}/>
    default:
      return <Box />;
  }
};
