import { Answer } from "../interfaces";
import { parseAnswers } from "./parseAnswers";
import { parseConditions } from "./parseConditions";

export const sanitizeAnswers = (
  answers: Answer[],
  conditions: { subSteps: any[]; questions: any[] }
): Answer[] => {
  const result: Answer[] = [];

  for (let i = 0; i < answers.length; i++) {
    const answer = answers[i];
    const { subStepId, questionId, isDummyField } = answer;

    const subStepCondition = conditions.subSteps.find((c: any) => c[subStepId]);
    const questionCondition = conditions.questions.find(
      (c: any) => c[questionId]
    );

    if (
      isDummyField == null &&
      subStepCondition &&
      !parseConditions(subStepCondition, parseAnswers(answers))
    ) {
      // debugger;
      continue;
    }

    if (
      isDummyField == null &&
      questionCondition &&
      !parseConditions(questionCondition, parseAnswers(answers))
    ) {
      // debugger;
      continue;
    }

    result.push(answers[i]);
  }

  return result;
};
