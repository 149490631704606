import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { MetaPixel } from '../../components/tracking/MetaPixel';
import { TikTokPixel } from '../../components/tracking/TikTokPixel';

export const RegistrationVerified = () => {
  return (
    <Flex>
      {/* <MetaPixel/>
    <TikTokPixel/> */}
      <Navigate to="/dashboard/membership" />
    </Flex>
  );
};
