import api from "../axios/axios";

export const loginUser = (data: { email: string; password: string }) => {
  return api.post("/auth/login", data);
};

export const verifyUser = (data: { verificationCode: string }) => {
  return api.post("/auth/verify", data);
};

export const registerUser = (data: {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
}) => {
  return api.post("/auth/register", data);
};

export const logoutUser = () => {
  return api.get("/auth/logout");
};

export const startPasswordReset = (data: { email: string }) => {
  return api.post("/auth/reset-password/initiate", data);
};

export const verifyPassword = (data: { resetCode: string }) => {
  return api.post("/auth/reset-password/verify", data);
};

export const completePasswordReset = (data: {
  resetCode: string;
  password: string;
  confirmPassword: string;
  _id: string;
}) => {
  return api.post("/auth/reset-password/complete", data);
};
