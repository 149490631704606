import React from 'react';

const ChartaAccented: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 238.97 62.3075"
    version="1.1"
    cursor="pointer"
  >
    <defs>
      <clipPath id="clip1">
        <path d="M 43 0 L 72 0 L 72 62.308594 L 43 62.308594 Z M 43 0 " />
      </clipPath>
      <clipPath id="clip2">
        <path d="M 204 0 L 238.96875 0 L 238.96875 62 L 204 62 Z M 204 0 " />
      </clipPath>
      <clipPath id="clip3">
        <path d="M 0 0 L 29 0 L 29 62.308594 L 0 62.308594 Z M 0 0 " />
      </clipPath>
    </defs>
    <g id="surface1">
      <g clipPath="url(#clip1)" clipRule="nonzero">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: '1',
          }}
          d="M 71.707031 44.320313 L 71.707031 48.105469 C 71.707031 50 71.347656 51.792969 70.632813 53.484375 C 69.910156 55.179688 68.925781 56.683594 67.660156 58.003906 C 66.398438 59.324219 64.921875 60.371094 63.230469 61.144531 C 61.539063 61.921875 59.714844 62.308594 57.765625 62.308594 C 56.101563 62.308594 54.410156 62.078125 52.6875 61.617188 C 50.96875 61.160156 49.417969 60.359375 48.039063 59.210938 C 46.664063 58.0625 45.527344 56.585938 44.640625 54.777344 C 43.75 52.96875 43.304688 50.660156 43.304688 47.851563 L 43.304688 14.113281 C 43.304688 12.105469 43.652344 10.242188 44.339844 8.519531 C 45.027344 6.796875 46.003906 5.308594 47.265625 4.046875 C 48.527344 2.78125 50.035156 1.792969 51.785156 1.074219 C 53.535156 0.359375 55.46875 0 57.59375 0 C 61.722656 0 65.082031 1.347656 67.660156 4.046875 C 68.925781 5.363281 69.910156 6.929688 70.632813 8.734375 C 71.347656 10.542969 71.707031 12.507813 71.707031 14.628906 L 71.707031 18.070313 L 62.929688 18.070313 L 62.929688 15.148438 C 62.929688 13.425781 62.441406 11.933594 61.464844 10.671875 C 60.492188 9.410156 59.167969 8.777344 57.507813 8.777344 C 55.328125 8.777344 53.878906 9.453125 53.160156 10.800781 C 52.441406 12.148438 52.085938 13.855469 52.085938 15.921875 L 52.085938 47.246094 C 52.085938 49.027344 52.472656 50.515625 53.246094 51.722656 C 54.023438 52.925781 55.414063 53.527344 57.421875 53.527344 C 57.992188 53.527344 58.609375 53.429688 59.273438 53.226563 C 59.929688 53.027344 60.546875 52.699219 61.121094 52.238281 C 61.636719 51.78125 62.066406 51.148438 62.410156 50.34375 C 62.757813 49.542969 62.929688 48.539063 62.929688 47.332031 L 62.929688 44.320313 "
        />
      </g>
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: '1',
        }}
        d="M 77.609375 61.792969 L 77.609375 0.515625 L 86.386719 0.515625 L 86.386719 26.765625 L 97.234375 26.765625 L 97.234375 0.515625 L 106.011719 0.515625 L 106.011719 61.792969 L 97.234375 61.792969 L 97.234375 34.511719 L 86.386719 34.511719 L 86.386719 61.792969 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: '1',
        }}
        d="M 132 40.363281 L 127.699219 18.160156 L 127.527344 18.160156 L 123.222656 40.363281 Z M 110.3125 61.789063 L 123.996094 0.515625 L 131.3125 0.515625 L 144.996094 61.789063 L 136.21875 61.789063 L 133.636719 48.625 L 121.671875 48.625 L 119.09375 61.789063 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: '1',
        }}
        d="M 158.078125 8.777344 L 158.078125 27.882813 L 163.070313 27.882813 C 164.617188 27.882813 165.851563 27.667969 166.769531 27.238281 C 167.6875 26.808594 168.40625 26.191406 168.917969 25.386719 C 169.378906 24.585938 169.695313 23.59375 169.867188 22.417969 C 170.039063 21.242188 170.125 19.878906 170.125 18.332031 C 170.125 16.78125 170.039063 15.417969 169.867188 14.242188 C 169.695313 13.066406 169.351563 12.046875 168.832031 11.1875 C 167.742188 9.582031 165.679688 8.777344 162.636719 8.777344 Z M 149.300781 61.792969 L 149.300781 0.515625 L 163.414063 0.515625 C 173.738281 0.515625 178.902344 6.511719 178.902344 18.503906 C 178.902344 22.117188 178.34375 25.1875 177.226563 27.710938 C 176.105469 30.238281 174.140625 32.273438 171.328125 33.820313 L 180.796875 61.792969 L 171.503906 61.792969 L 163.328125 35.628906 L 158.078125 35.628906 L 158.078125 61.792969 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: '1',
        }}
        d="M 190.179688 61.792969 L 190.179688 8.777344 L 180.023438 8.777344 L 180.023438 0.515625 L 209.113281 0.515625 L 209.113281 8.777344 L 198.957031 8.777344 L 198.957031 61.792969 "
      />
      <g clipPath="url(#clip2)" clipRule="nonzero">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: '1',
          }}
          d="M 225.980469 40.363281 L 221.675781 18.160156 L 221.503906 18.160156 L 217.199219 40.363281 Z M 204.292969 61.789063 L 217.976563 0.515625 L 225.289063 0.515625 L 238.972656 61.789063 L 230.195313 61.789063 L 227.613281 48.625 L 215.652344 48.625 L 213.070313 61.789063 "
        />
      </g>
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: '1',
        }}
        d="M 15.433594 28.878906 C 15.277344 28.742188 15.105469 28.640625 14.910156 28.585938 C 14.71875 28.53125 14.527344 28.5 14.34375 28.5 C 14.121094 28.5 13.917969 28.546875 13.726563 28.640625 C 13.539063 28.734375 13.371094 28.863281 13.230469 29.023438 C 13.089844 29.183594 12.976563 29.367188 12.894531 29.574219 C 12.816406 29.78125 12.773438 30 12.773438 30.230469 L 12.769531 34.316406 C 12.769531 34.574219 12.808594 34.816406 12.890625 35.035156 C 12.96875 35.253906 13.078125 35.445313 13.21875 35.609375 C 13.511719 35.9375 13.886719 36.101563 14.351563 36.105469 C 14.589844 36.105469 14.804688 36.0625 15.003906 35.972656 C 15.199219 35.886719 15.367188 35.765625 15.511719 35.613281 C 15.652344 35.457031 15.761719 35.277344 15.839844 35.066406 C 15.917969 34.855469 15.957031 34.628906 15.957031 34.382813 L 15.960938 30.269531 C 15.964844 29.925781 15.914063 29.644531 15.8125 29.421875 C 15.714844 29.203125 15.585938 29.019531 15.433594 28.878906 "
      />
      <g clipPath="url(#clip3)" clipRule="nonzero">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(84.375%,63.084412%,38.818359%)',
            fillOpacity: '1',
          }}
          d="M 19.511719 51.597656 C 19.425781 51.894531 17.644531 51.953125 17.238281 51.757813 C 17.035156 51.65625 15.246094 47.398438 15.058594 47.398438 L 13.671875 47.398438 C 13.484375 47.398438 11.695313 51.65625 11.492188 51.757813 C 11.085938 51.953125 9.304688 51.894531 9.21875 51.597656 L 7.183594 31.679688 C 7.171875 31.535156 7.191406 31.382813 7.242188 31.246094 L 14.160156 13 C 14.199219 12.902344 14.28125 12.859375 14.363281 12.859375 C 14.449219 12.859375 14.53125 12.902344 14.570313 13 L 21.488281 31.246094 C 21.539063 31.382813 21.558594 31.535156 21.546875 31.679688 Z M 28.726563 29.941406 L 28.515625 28.527344 C 28.515625 28.507813 28.507813 28.492188 28.503906 28.476563 L 17.140625 0.371094 C 17.101563 0.269531 17.007813 0.199219 16.902344 0.199219 L 11.828125 0.199219 C 11.722656 0.199219 11.628906 0.269531 11.589844 0.371094 L 0.226563 28.476563 C 0.222656 28.492188 0.214844 28.507813 0.214844 28.527344 L 0.00390625 29.941406 C 0 29.960938 0 29.984375 0 30.003906 L 2.84375 59.492188 C 2.859375 59.636719 2.929688 59.773438 3.035156 59.871094 L 5.644531 62.066406 C 5.75 62.167969 5.886719 62.226563 6.023438 62.226563 L 9.414063 62.253906 L 9.839844 62.050781 L 11.527344 60.230469 C 11.539063 60.214844 11.554688 60.195313 11.5625 60.175781 L 14.179688 56.296875 C 14.21875 56.21875 14.292969 56.175781 14.363281 56.175781 C 14.4375 56.175781 14.511719 56.21875 14.550781 56.296875 L 17.167969 60.175781 C 17.179688 60.195313 17.191406 60.214844 17.203125 60.230469 L 18.890625 62.050781 L 19.320313 62.253906 L 22.707031 62.226563 C 22.84375 62.226563 22.980469 62.167969 23.085938 62.066406 L 25.695313 59.871094 C 25.800781 59.773438 25.871094 59.636719 25.886719 59.492188 L 28.730469 30.003906 C 28.730469 29.984375 28.730469 29.960938 28.726563 29.941406 "
        />
      </g>
    </g>
  </svg>
);

export default ChartaAccented;