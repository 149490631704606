import {
  Button,
  Flex,
  Text,
  Image,
  useColorModeValue,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Grid,
  GridItem,
  Avatar,
  useDisclosure
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createContract,
  getOtherPartyUnfinishedNegotiations,
  getOtherPartyUnfinishedNegotiationsCount,
} from "../../api";
import { dashboardViewAtom, userAtom } from "../../atoms";
import { capitalize } from "../../utils/capitalize";
import { Dropdown } from "../icons/Dropdown";
import { AlertModal } from '../core/AlertModal';

export interface SentOffersCondensedProps extends BoxProps {}

export const SentOffersCondensed: React.FC<SentOffersCondensedProps> = ({
  ...props
}) => {
  const bg = useColorModeValue("brand.secondary1", "brand.secondary2");
  const color = useColorModeValue("brand.secondary2", "brand.secondary1");
  const highlightColor = useColorModeValue("#6B30BA", "brand.primary");

  const [user] = useAtom(userAtom);
  const [, setView] = useAtom(dashboardViewAtom);
  const [negotiations, setNegotiations] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();
  const {isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    const getNegotiations = async () => {
      const { data } = await getOtherPartyUnfinishedNegotiations(3, 0);
      setNegotiations(data);
    };

    getNegotiations();
  }, []);

  useEffect(() => {
    const getCount = async () => {
      const { data } = await getOtherPartyUnfinishedNegotiationsCount();
      setCount(data.count);
    };

    getCount();
  }, [setCount]);

  const handleOnClick = async (negotiation: any) => {
    if (
      negotiation.status === "active-contract" ||
      negotiation.status === "created"
    ) {
      navigate(`/negotiation/${negotiation._id}`);
    } else if (negotiation.contract) {
      navigate(`/negotiation/${negotiation._id}`);
    } else {
      const { data } = await createContract(negotiation._id);
      navigate(`/negotiation/${negotiation._id}`);
      //navigate(`/contract/${data.contract._id}`);
    }
  };

  return (
    <Box {...props}>
      <Accordion allowToggle={false} defaultIndex={0} variant="summary">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                <Flex gap="15.3px" w="100%" alignItems="center">
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="12px"
                    color={color}
                  >
                    Sent offers ({count})
                  </Text>
                  <Button
                    as="span"
                    color={highlightColor}
                    variant="transparent"
                    h="24px"
                    w="88.79px"
                    onClick={(e) => {
                      e.preventDefault();
                      setView("SentOffers");
                    }}
                  >
                    View all
                  </Button>
                </Flex>
                {/* <Dropdown
                        color={highlightColor}
                        h="4px"
                        w="12px"
                        sx={
                          isExpanded
                            ? {
                                transform: "rotate(180deg)",
                              }
                            : {}
                        }
                      /> */}
              </AccordionButton>

              <AccordionPanel>
                {negotiations.map((negotiation: any) => {
                  const isInitiator =
                    negotiation.initiator.email === user?.email;

                  let otherParty = isInitiator
                    ? negotiation.receiver
                    : negotiation.initiator;

                  if (otherParty === null || otherParty === undefined) {
                    otherParty = {
                      firstName: isInitiator
                        ? negotiation.receiverEmail
                        : negotiation.initiatorEmail,
                      lastName: "",
                      profileImageUrl: "",
                    };
                  }

                  return (
                    <Grid
                      templateColumns={{
                        base: "repeat(2, 1fr)",
                        lg: "repeat(3, 1fr)",
                      }}
                      background={bg}
                      mb="9px"
                      key={negotiation._id}
                      p="16px 18px"
                      borderRadius="4px"
                    >
                      <GridItem>
                        <Flex flexDirection="column" gap="8.75px">
                          <Text>
                            {negotiation.contractType.subjectType}: "
                            {negotiation.subject}"
                          </Text>
                          <Flex flexDirection="column" gap="8px">
                            <Text
                              color={color}
                              fontSize="12px"
                              fontWeight="700"
                              lineHeight="14px"
                            >
                              Sent on:{" "}
                              {new Date(negotiation.createdAt).toDateString()}
                            </Text>
                            <Text
                              color={highlightColor}
                              fontSize="10px"
                              fontWeight="700"
                              lineHeight="12px"
                            >
                              {negotiation.contractType.name}
                            </Text>
                          </Flex>
                        </Flex>
                      </GridItem>
                      <GridItem>
                        <Flex
                          gap="8px"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Avatar
                            src={otherParty.profileImageUrl}
                            size="sm"
                          />
                          <Flex flexDirection="column">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight="16px"
                              color={color}
                            >
                              {otherParty.firstName} {otherParty.lastName}
                            </Text>
                            <Text
                              fontSize="10px"
                              fontWeight="700"
                              lineHeight="12px"
                              color={color}
                            >
                              {isInitiator
                                ? capitalize(negotiation.receiverRoleLabel || negotiation.receiverRole)
                                : capitalize(negotiation.initiatorRoleLabel || negotiation.initiatorRole)}
                            </Text>
                          </Flex>
                        </Flex>
                      </GridItem>
                      <GridItem
                        colSpan={{ base: 2, lg: 1 }}
                        mt={{ base: "24px", lg: "0" }}
                      >
                        <Flex
                          flexDirection={{ base: "row", lg: "column" }}
                          gap="8px"
                          alignItems={{ lg: "flex-end" }}
                        >
                          <Button
                            h={{ base: "40px", lg: "24px" }}
                            width={{ base: "50%", lg: "103px" }}
                            borderRadius="6px"
                            padding={{ base: "", lg: "0px, 8px, 0px, 8px" }}
                            onClick={onOpen}
                          >
                            Remind
                          </Button>
                          <Button
                            variant="transparent"
                            h={{ base: "40px", lg: "24px" }}
                            width={{ base: "50%", lg: "103px" }}
                            borderRadius="6px"
                            padding={{
                              base: "0 12px 0 12px",
                              lg: "0px, 8px, 0px, 8px",
                            }}
                            onClick={() => handleOnClick(negotiation)}
                          >
                            Review
                          </Button>
                        </Flex>
                      </GridItem>
                    </Grid>
                  );
                })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <AlertModal
        isOpen={isOpen}
        onAccept={onClose}
        acceptText="OK"
        title="Reminder Successful!"
        text="A reminder has been scheduled and should be received within the next 72 hours."
        variant="success"
      />
    </Box>
  );
};
