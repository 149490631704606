import {
  Button,
  Flex,
  FlexProps,
  Text,
  Image,
  useColorModeValue,
  Grid,
  GridItem,
  Avatar
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createContract,
  getUserUnfinishedNegotiations,
  getUserUnfinishedNegotiationsNoLimit
} from '../../api';
import { userAtom } from '../../atoms';
import { capitalize } from '../../utils/capitalize';
import { ListHeader } from './ListHeader';

export interface ActionRequiredProps extends FlexProps {}

export const ActionRequired: React.FC<ActionRequiredProps> = ({ ...props }) => {
  const bg = useColorModeValue('brand.secondary1', 'brand.secondary2');
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const highlightColor = useColorModeValue('#6B30BA', 'brand.primary');

  const [user] = useAtom(userAtom);
  const [negotiations, setNegotiations] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getNegotiations = async () => {
      const { data } = await getUserUnfinishedNegotiationsNoLimit();
      setNegotiations(data);
    };

    getNegotiations();
  }, []);

  const handleOnClick = async (negotiation: any) => {
    if (negotiation.status === 'created') {
      navigate(
        `/step-through/${negotiation.contractType._id}?negotiation=${negotiation._id}`
      );
    } else if (negotiation.status === 'active-contract') {
      navigate(`/negotiation/${negotiation._id}`);
    } else if (negotiation.contract) {
      navigate(`/negotiation/${negotiation._id}`);
    } else {
      //const { data } = await createContract(negotiation._id);
      navigate(`/negotiation/${negotiation._id}`);
      //navigate(`/contract/${data.contract._id}`);
    }
  };

  return (
    <Flex flexDir="column" {...props}>
      <ListHeader title="Action Required" mb="16px" />
      {negotiations.map((negotiation) => {
        const isInitiator = negotiation.initiator.email === user?.email;
        let otherParty = isInitiator
          ? negotiation.receiver
          : negotiation.initiator;

        if (otherParty === null || otherParty === undefined) {
          otherParty = {
            firstName: isInitiator
              ? negotiation.receiverEmail
              : negotiation.initiatorEmail,
            lastName: '',
            profileImageUrl: ''
          };
        }

        let buttonText = '';

        if (negotiation.numMissingTerms === 0) {
          buttonText = 'View Contract';
        } else {
          buttonText = 'Negotiate';
        }

        return (
          <Grid
            background={bg}
            p="16px 18px"
            borderRadius="4px"
            mb="9px"
            key={negotiation._id}
            alignItems="center"
            templateColumns={{
              base: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)'
            }}
          >
            <GridItem>
              <Flex flexDirection="column" gap="8.75px">
                <Text>
                  {negotiation.contractType.subjectType}: "{negotiation.subject}
                  "
                </Text>
                <Text
                  color={highlightColor}
                  fontSize="10px"
                  fontWeight="700"
                  lineHeight="12px"
                >
                  {negotiation.contractType.name}
                </Text>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex gap="8px" alignItems="center">
                <Avatar src={otherParty.profileImageUrl} size="sm" />
                <Flex flexDirection="column">
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="16px"
                    color={color}
                  >
                    {otherParty.firstName} {otherParty.lastName}
                  </Text>
                  <Text
                    fontSize="10px"
                    fontWeight="700"
                    lineHeight="12px"
                    color={color}
                  >
                    {isInitiator
                      ? capitalize(negotiation.receiverRoleLabel || negotiation.receiverRole)
                      : capitalize(negotiation.initiatorRoleLabel || negotiation.initiatorRole)}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex flexDir="column">
                <Text
                  color={color}
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="14px"
                >
                  Sent on: {new Date(negotiation.createdAt).toDateString()}
                </Text>
                <Text
                  color={color}
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="14px"
                >
                  at: {new Date(negotiation.createdAt).toLocaleTimeString()}
                </Text>
              </Flex>
            </GridItem>
            <GridItem>
              <Button
                variant="transparent"
                h="24px"
                width="103px"
                borderRadius="6px"
                padding="0px, 8px, 0px, 8px"
                onClick={() => handleOnClick(negotiation)}
              >
                {buttonText}
              </Button>
            </GridItem>
          </Grid>
        );
      })}
    </Flex>
  );
};
