import api from "../axios/axios";

export const createNegotiation = (data: any) => {
  return api.post("/negotiation", data);
};

export const getNegotiationById = (id: string) => {
  return api.get(`/negotiation/${id}`);
};

export const updateNegotiation = (id: string, data: any) => {
  return api.put(`/negotiation/${id}`, data);
};

export const getUserUnfinishedNegotiations = (
  limit: number,
  offset: number
) => {
  return api.get(
    `/negotiation/need-current-user-response?limit=${limit}&offset=${offset}`
  );
};

export const getUserUnfinishedNegotiationsNoLimit = () => {
  return api.get(`/negotiation/need-current-user-response`);
};

export const getOtherPartyUnfinishedNegotiations = (
  limit: number,
  offset: number
) => {
  return api.get(
    `/negotiation/need-other-response?limit=${limit}&offset=${offset}`
  );
};

export const getOtherPartyUnfinishedNegotiationsNoLimit = () => {
  return api.get(`/negotiation/need-other-response`);
};

export const getUserUnfinishedNegotiationsCount = () => {
  return api.get(`/negotiation/need-current-user-response/count`);
};

export const getOtherPartyUnfinishedNegotiationsCount = () => {
  return api.get(`/negotiation/need-other-response/count`);
};

export const getNegotiationRoles = (id: string) => {
  return api.get(`/negotiation/${id}/roles`);
};
