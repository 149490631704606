import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  FlexProps,
  FormErrorMessage,
  textDecoration,
  useDisclosure
} from '@chakra-ui/react';
import { Footer } from '../components/core/Footer';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { loginUser, registerUser } from '../api';
import { useAtom } from 'jotai';
import { redirectAtom, userAtom, navbarColorAtom } from 'src/atoms';
import { AlertModal } from 'src/components/core/AlertModal';
import { Helmet } from 'react-helmet';

export interface RegisterProps extends FlexProps {}

export const Register: React.FC<RegisterProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [redirect] = useAtom(redirectAtom);
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({});

  const onSubmit = async (values: any) => {
    try {
      values = {
        ...values,
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        email: values?.email?.trim()
      };

      await registerUser(values);

      const response = await loginUser({
        email: values.email,
        password: values.password
      });

      setUser(response.data);
    } catch (err: any) {
      if (
        err?.response?.data?.message === 'User with that email already exists'
      ) {
        onOpen();
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/');
      }
    }
  }, [user, navigate, redirect]);

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        {`<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-54ZCKP9');</script>`}
      </Helmet>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        h={{ lg: '100vh' }}
        minH={{ base: '100vh', lg: '0' }}
        overflow={{ lg: 'hidden' }}
        {...props}
      >
        <AlertModal
          isOpen={isOpen}
          onAccept={() => {
            onClose();
          }}
          acceptText="Continue"
          title="An account with that email already exists"
          variant="warning"
        />
        <Flex
          flexGrow="1"
          overflow={{ lg: 'hidden' }}
          minH={{ base: '100%', lg: '0' }}
        >
          <Box
            flexGrow="1"
            h="100%"
            bgImage="url(/login.png)"
            bgPosition="center"
            bgSize="cover"
            display={{ base: 'none', lg: 'initial' }}
          ></Box>
          <Box
            w={{ base: '100%', lg: '620px' }}
            background="#1E2029"
            px={{ base: '8px', lg: '43px' }}
            overflowY="auto"
          >
            <Flex
              as="form"
              noValidate
              flexDirection="column"
              w={{ base: '100%', lg: '332px' }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Heading fontSize="92px" fontWeight="400" mb="24px">
                Sign Up
              </Heading>
              <FormControl mb="16px" isInvalid={!!errors.firstName}>
                <FormLabel>First name</FormLabel>
                <Input {...register('firstName', { required: true })} />
                <FormErrorMessage>First name is required</FormErrorMessage>
              </FormControl>
              <FormControl mb="16px" isInvalid={!!errors.lastName}>
                <FormLabel>Last name</FormLabel>
                <Input {...register('lastName', { required: true })} />
                <FormErrorMessage>Last name is required</FormErrorMessage>
              </FormControl>
              <FormControl mb="16px" isInvalid={!!errors.email}>
                <FormLabel>Email address</FormLabel>
                <Input
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address'
                    }
                  })}
                />
                <FormErrorMessage>
                  {errors.email?.type === 'required'
                    ? 'Email is required'
                    : 'Invalid email format'}
                </FormErrorMessage>
              </FormControl>
              <FormControl mb="16px" isInvalid={!!errors.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register('password', { required: true })}
                />
                <FormErrorMessage>Password is required</FormErrorMessage>
              </FormControl>
              <FormControl mb="20px" isInvalid={!!errors.confirmPassword}>
                <FormLabel>Confirm password</FormLabel>
                <Input
                  type="password"
                  {...register('confirmPassword', {
                    required: true,
                    validate: (value: string) => {
                      if (watch('password') !== value) {
                        return 'Your passwords do no match';
                      }
                    }
                  })}
                />
                <FormErrorMessage>
                  {errors.confirmPassword?.type === 'required'
                    ? 'Password confirmation required'
                    : 'Passwords do not match'}
                </FormErrorMessage>
              </FormControl>
              <Flex mb="32px" gap="16px">
                <Button minW={{ lg: '138px' }} flex="1" h="40px" type="submit" data-heap-id="sign-up">
                  Sign up
                </Button>

                <Button
                  variant="transparent"
                  minW={{ lg: '178px' }}
                  h="40px"
                  flex="1"
                  onClick={() => navigate('/login')}
                >
                  I have an account
                </Button>
              </Flex>

              <Text
                display="inline"
                fontSize="14px"
                fontWeight="400"
                lineHeight="20px"
              >
                By clicking “Sign Up” you agree to our{' '}
                <b>
                  <a
                    href={process.env.PUBLIC_URL + '/tc.pdf'}
                    style={{ textDecoration: 'underline' }}
                  >
                    Terms of Service
                  </a>
                </b>{' '}
                and acknowledge that our <b>Privacy Policy</b> applies to you.
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
};
