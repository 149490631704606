import {
  Flex,
  Heading,
  useColorModeValue,
  FlexProps,
  Text,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";

export interface EnterCodeProps extends FlexProps {
  onCodeSubmitted: (code: string) => void;
}

export const EnterCode: React.FC<EnterCodeProps> = ({ onCodeSubmitted }) => {
  const bg = useColorModeValue("#EEEEEE", "#1E2029");
  const color = useColorModeValue("#1B1C31", "brand.secondary1");

  const [code, setCode] = useState<string>("");

  return (
    <Flex
      overflow="auto"
      bg={bg}
      borderRadius="5px"
      h={{ base: "100%", lg: "auto" }}
      w={{ base: "100%", lg: "auto" }}
      p="32px"
      flexDirection="column"
      alignItems="center"
      color={color}
    >
      <Heading fontSize="56px" fontWeight="400" lineHeight="50.4px">
        Email Verification
      </Heading>
      <Text
        align="center"
        mt="24px"
        fontSize="16px"
        fontWeight="400"
        lineHeight="20px"
      >
        Please enter the verification code that was sent to your email.
      </Text>
      <FormControl mt="72px" w={{ base: "100%", lg: "70%" }}>
        <Input
          borderColor={color}
          placeholder="Verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </FormControl>
      <Button
        mt="24px"
        w="138px"
        h="40px"
        variant="gradient"
        onClick={() => onCodeSubmitted(code)}
      >
        Submit
      </Button>
    </Flex>
  );
};
