import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Notification: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <circle cx="8" cy="8.5" r="8" fill="currentColor" />
      <mask
        id="mask0_757_606"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="15"
        height="15"
      >
        <rect x="1.14062" y="1.64062" width="13.875" height="13.875" />
      </mask>
      <g mask="url(#mask0_757_606)">
        <path d="M8.425 9.15625V8.13008L11.3012 5.25391L12.3273 6.28008L9.45117 9.15625H8.425ZM12.7031 5.9043L13.1945 5.41289C13.2523 5.35508 13.2812 5.28513 13.2812 5.20303C13.2812 5.12132 13.2523 5.05156 13.1945 4.99375L12.5875 4.38672C12.5297 4.32891 12.4599 4.3 12.3782 4.3C12.2961 4.3 12.2262 4.32891 12.1684 4.38672L11.677 4.87812L12.7031 5.9043ZM3.45312 12.625V11.4688H4.60938V7.42188C4.60938 6.60286 4.85508 5.88734 5.34648 5.2753C5.83789 4.66364 6.45938 4.26146 7.21094 4.06875V3.66406C7.21094 3.42318 7.29534 3.21852 7.46416 3.05009C7.63258 2.88128 7.83724 2.79688 8.07812 2.79688C8.31901 2.79688 8.52367 2.88128 8.69209 3.05009C8.86091 3.21852 8.94531 3.42318 8.94531 3.66406V4.06875C9.1862 4.12656 9.41263 4.21078 9.62461 4.32139C9.83659 4.43239 10.0341 4.56016 10.2172 4.70469L9.39336 5.52852C9.21029 5.39362 9.00794 5.29014 8.78633 5.21806C8.56471 5.1456 8.32865 5.10938 8.07812 5.10938C7.44219 5.10938 6.89779 5.33581 6.44492 5.78867C5.99206 6.24154 5.76562 6.78594 5.76562 7.42188V11.4688H10.3906V9.85L11.5469 8.69375V11.4688H12.7031V12.625H3.45312ZM8.07812 14.3594C7.76016 14.3594 7.48805 14.2463 7.26181 14.02C7.03519 13.7934 6.92188 13.5211 6.92188 13.2031H9.23438C9.23438 13.5211 9.12126 13.7934 8.89502 14.02C8.66839 14.2463 8.39609 14.3594 8.07812 14.3594Z" />
      </g>
    </Icon>
  );
};
