import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Check: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <mask
        id="mask0_757_15781"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" />
      </mask>
      <g mask="url(#mask0_757_15781)">
        <path d="M6.58322 11.2492L3.76642 8.4164L4.60002 7.5828L6.58322 9.55L11.4 4.75L12.2336 5.5996L6.58322 11.2492Z" />
      </g>
    </Icon>
  );
};
