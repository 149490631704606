export const handleDisplayResponseError = (errorResponse: any) => {
  let message = "";
  if (Array.isArray(errorResponse?.response?.data?.message)) {
    for (const line of errorResponse.response.data.message) {
      message += `${line} `;
    }
    alert(message);
  } else if (errorResponse?.response?.data?.message != null) {
    message = errorResponse.response.data.message;
    alert(message);
  }
  console.error(message);
};