import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Stepper } from '../components/step-through/Stepper';
import { HelpCenter } from '../components/core/HelpCenter';
import { Wizard } from '../components/step-through/Wizard';
import { stepThroughDataAtom, navbarColorAtom } from '../atoms';
import {
  useParams,
  useSearchParams,
  Navigate,
  useNavigate
} from 'react-router-dom';
import {
  getContractType,
  getNegotiatingContractType,
  getNegotiationRoles
} from '../api';
import { useApiHelpers } from 'src/hooks/useApiHelpers';

export const StepThrough = () => {
  const [params] = useSearchParams();
  const negotiation = params.get('negotiation');
  const { showLoading, showServerError, hideLoading } = useApiHelpers();
  const navigate = useNavigate();

  const { id } = useParams();

  const [stepThroughData, setStepThroughData] = useAtom(stepThroughDataAtom);
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const { contractType: contract } = stepThroughData;

  useEffect(() => {
    const getContract = async () => {
      let contractType: any;
      let role: any = null;

      if (negotiation) {
        return navigate(`/negotiation/${negotiation}?first-counter=true`);
      } else {
        const result = await getContractType(id!);
        contractType = result.data;
      }

      setStepThroughData((prev) => {
        return {
          contractType,
          formAnswers: negotiation ? [role] : prev.formAnswers,
          wizardIndex: {
            step: 0,
            subStep: 0
          },
          showHelpCenter: false,
          helpCenterTarget: '',
          ...(negotiation ? { negotiation } : {})
        };
      });
    };

    try {
      showLoading();
      getContract();
    } catch (error) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
  }, [
    id,
    negotiation,
    setStepThroughData,
    showLoading,
    hideLoading,
    showServerError
  ]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    setStepThroughData((data) => ({
      ...data,
      formAnswers: []
    }));

    if (negotiation) {
      navigate('/');
    } else {
      navigate('/pre-step-through');
    }
  };

  //If the role hasn't been selected - i.e. in the case of a refresh
  if (!negotiation && !stepThroughData.formAnswers.length) {
    return <Navigate to="/pre-step-through" />;
  }

  if (!contract) {
    return <></>;
  }

  return (
    <Flex
      flexDirection="column"
      h={{ lg: '100vh' }}
      minH={{ base: '100vh', lg: 'auto' }}
      bgImage="url(/studio.jpg)"
      bgPosition="center"
      bgSize="cover"
      overflowY={{ lg: 'hidden' }}
    >
      <Flex
        h={{ lg: '100%' }}
        alignItems="center"
        justifyContent="space-between"
        overflowY={{ base: 'auto', lg: 'hidden' }}
      >
        <Stepper
          h={{ lg: '100%' }}
          overflowY="auto"
          display={{ base: 'none', lg: 'inherit' }}
        />
        <Wizard
          flex="1"
          h={{ lg: '80%' }}
          mx={{ base: '8px', lg: '16px' }}
          overflowY={{ lg: 'hidden' }}
          my={{ base: '10px', lg: '0' }}
          onGoBack={handleGoBack}
        />
        <HelpCenter h="100%" overflowY="auto" />
      </Flex>
    </Flex>
  );
};
