import React, { useEffect, useRef, useState } from 'react';
import {
  BoxProps,
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  getUserProfile,
  saveUserProfileImage,
  updateUserProfile
} from 'src/api';
import { useApiHelpers } from 'src/hooks/useApiHelpers';

export interface AccountSettingsProps extends BoxProps {}

export const AccountSettings: React.FC<AccountSettingsProps> = ({
  ...props
}) => {
  const { reset, register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const profileImageFile = useRef<HTMLInputElement | null>(null);
  const [newProfileImageBase64, setNewProfileImageBase64] = useState<string>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const { showLoading, showServerError, hideLoading } = useApiHelpers();

  const toBase64 = (file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString()?.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });

  const onProfileImageSelected = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    const imageUrl = URL.createObjectURL(file);
    setProfileImageUrl(imageUrl);
    const base64: string = await toBase64(file);
    setNewProfileImageBase64(base64);
  };
  useEffect(() => {
    const getProfile = async () => {
      const { data } = await getUserProfile();
      setProfileImageUrl(data.profileImageUrl);
      reset(data);
    };

    try {
      showLoading();
      getProfile();
    } catch {
      showServerError();
      hideLoading();
    } finally {
      hideLoading();
    }
  }, [reset]);

  const onSubmit = async (values: any) => {
    values = {
      ...values,
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      professionalName: values?.professionalName?.trim()
    };

    try {
      showLoading();
      if (newProfileImageBase64 != null) {
        await saveUserProfileImage(newProfileImageBase64);
      }
      const { data } = await updateUserProfile(values);
      setProfileImageUrl(data.profileImageUrl);
      reset(data);
    } catch (err) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
  };

  return (
    <Box as="form" noValidate onSubmit={handleSubmit(onSubmit)} m="24px 20px">
      <Flex justifyContent={{ base: 'center', lg: 'space-between' }}>
        <Heading fontSize="40px" fontWeight="400" lineHeight="48px">
          Account Settings
        </Heading>
        <Flex gap="16px">
          {/* <Button
            type="button"
            variant="outline"
            display={{ base: 'none', lg: 'initial' }}
          >
            Update Payment Info
          </Button> */}
          {/* <Button
            type="button"
            variant="outline"
            display={{ base: 'none', lg: 'initial' }}
            onClick={() => navigate('/dashboard/membership')}
          >
            Upgrade Membership
          </Button> */}
          <Button type="submit" display={{ base: 'none', lg: 'initial' }}>
            Save changes
          </Button>
        </Flex>
      </Flex>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: '40px', lg: '70px' }}
        mt="32px"
      >
        <Flex
          flexDir="column"
          width={{ base: '100%', lg: '155px' }}
          gap="10.5px"
          alignItems="center"
        >
          <Avatar src={profileImageUrl} size="2xl" />
          <p style={{ fontSize: '10px' }}>Max size: 20mb</p>
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              profileImageFile.current?.click();
            }}
            size="sm"
          >
            Upload photo
          </Button>
          <Button
            type="button"
            variant="ghost"
            onClick={() => setProfileImageUrl('')}
            hidden={!profileImageUrl}
            size="sm"
          >
            x Remove photo
          </Button>
          <input
            type="file"
            id="file"
            ref={profileImageFile}
            style={{ display: 'none' }}
            onChange={onProfileImageSelected}
            accept="image/png, image/gif, image/jpeg"
          />
        </Flex>
        <Flex flex="1" flexDir="column">
          <Flex gap="24px" mb="16px" width="100%" alignItems="center">
            <Text
              fontSize="12px"
              fontWeight="700"
              lineHeight="18px"
              flex="0"
              width="auto"
            >
              PERSONAL INFORMATION
            </Text>
            <Divider flex="1" />
          </Flex>

          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '30px' }}
            mb="30px"
          >
            <FormControl>
              <FormLabel>First name</FormLabel>
              <Input {...register('firstName')} />
            </FormControl>
            <FormControl>
              <FormLabel>Last name</FormLabel>
              <Input {...register('lastName')} />
            </FormControl>
          </Flex>

          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '30px' }}
            mb="30px"
          >
            <FormControl>
              <FormLabel>Professional name</FormLabel>
              <Input {...register('professionalName')} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone number</FormLabel>
              <Input {...register('phoneNumber')} />
            </FormControl>
          </Flex>

          <Flex gap="24px" mb="16px" width="100%" alignItems="center">
            <Text
              fontSize="12px"
              fontWeight="700"
              lineHeight="18px"
              flex="0"
              width="auto"
            >
              ADDRESS
            </Text>
            <Divider flex="1" />
          </Flex>

          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '30px' }}
            mb="30px"
          >
            <FormControl>
              <FormLabel>Address line 1</FormLabel>
              <Input {...register('addressLine1')} />
            </FormControl>
            <FormControl>
              <FormLabel>Address line 2</FormLabel>
              <Input {...register('addressLine2')} />
            </FormControl>
          </Flex>

          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '10px', lg: '30px' }}
            mb={{ base: '10px', lg: '30px' }}
          >
            <FormControl>
              <FormLabel>Zip code</FormLabel>
              <Input {...register('zipCode')} />
            </FormControl>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input {...register('city')} />
            </FormControl>
            <FormControl>
              <FormLabel>State</FormLabel>
              <Input {...register('state')} />
            </FormControl>
          </Flex>
        </Flex>

        <Flex gap="16px" display={{ base: 'flex', lg: 'none' }}>
          {/* <Button
            type="button"
            variant="outline"
          >
            Update Payment Info
          </Button> */}
          {/* <Button
            type="button"
            variant="outline"
            onClick={() => navigate('/dashboard/membership')}
          >
            Upgrade Membership
          </Button> */}
          <Button type="submit">Save changes</Button>
        </Flex>
      </Flex>
    </Box>
  );
};
