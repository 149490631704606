import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { getUserCredentials } from 'src/api/payment';
import { userAtom, navbarColorAtom } from 'src/atoms';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { UserCredit } from '../icons/UserCredit';
import ChartaAccented from './ChartaAccented';
import { Menu as MenuIcon } from '../icons/Menu';
import { logoutUser } from 'src/api';
import { DemoVideoContext } from '../../providers/demoVideoProvider';

export interface NavBarProps extends BoxProps {}

export const NavBar: React.FC<NavBarProps> = ({ ...props }) => {
  // const { toggleColorMode } = useColorMode();
  const { showLoading, showServerError, hideLoading } = useApiHelpers();
  const [user, setUser] = useAtom(userAtom);
  const [navbarColor] = useAtom(navbarColorAtom);
  const [credits, setCredits] = useState<number>(0);
  const bg = useColorModeValue('#6B30BA', 'rgba(27, 28, 49, 0.8)');
  const navigate = useNavigate();
  const { showVideo } = useContext(DemoVideoContext);

  const setUserCredits = useCallback(async () => {
    const { data } = await getUserCredentials();
    setCredits(data.credits || 0);
  }, []);

  useEffect(() => {
    if (!user) return;
    try {
      showLoading();
      setUserCredits();
    } catch {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserCredits, user]);

  const logout = async () => {
    setUser(null);
    try {
      await logoutUser();
    } catch {
    } finally {
      navigate('/login');
    }
  };

  return (
    <chakra.header
      h="64px"
      bg={bg}
      py="12px"
      px="32px"
      background={navbarColor}
      {...props}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Box w={{ base: '92px', md: '167px' }} h={{ base: '24px', md: '40px' }}>
          <Link to={'/'}>
            <ChartaAccented />
          </Link>
        </Box>

        <HStack spacing="20px" as="nav">
          {/* <Switch size="sm" onChange={() => toggleColorMode()} /> */}
          {!!user && (
            <>
              <Flex align="center" justify="space-between">
                <UserCredit />
                <Text
                  pl="4px"
                  fontSize="12px"
                  fontWeight="600"
                  lineHeight="14px"
                  color="#FFC700"
                >
                  {credits} Credits
                </Text>
              </Flex>
              <Flex
                justifyContent="flex-end"
                alignItems="center"
              >
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<MenuIcon height="8px" width="21.3px" />}
                    variant="ghost"
                  />
                  <MenuList>
                    <MenuItem>
                      <NavLink to={'/dashboard'} end>
                        Dashboard
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <Flex onClick={showVideo}>
                        Demo Video
                      </Flex>
                    </MenuItem>
                    <MenuItem>
                      <NavLink to={'/dashboard/membership'} end>
                        Upgrade Plan
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <a href="https://blog.getcharta.com" target="_blank">
                        Charta U
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <NavLink to={'/dashboard/settings'}>
                        Account Settings
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <a href="https://getcharta.com/contact" target="_blank">
                        Contact
                      </a>
                    </MenuItem>
                    <MenuItem onClick={() => logout()}>Sign Out</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}
        </HStack>
      </Flex>
    </chakra.header>
  );
};

//TODO: create mobile version of this: https://levelup.gitconnected.com/create-a-responsive-navigation-bar-using-chakraui-6489473e933
