import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Next: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 9 9" {...props}>
      <path
        d="M6.83369 8.81543V0.81543H8.03369V8.81543H6.83369ZM0.0336914 8.41543V1.21543L5.63369 4.81543L0.0336914 8.41543Z"
        fill="currentColor"
      />
    </Icon>
  );
};
