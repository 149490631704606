import React, { useState } from 'react';
import { Flex, Box, Heading } from '@chakra-ui/react';

export interface IndustrySelectionProps {
  onIndustrySelected: (id: string) => void;
}

export const IndustrySelection: React.FC<IndustrySelectionProps> = ({
  onIndustrySelected
}) => {
  const [industries,] = useState<
  { _id: string; name: string; status: string }[]
>([
  {
    _id: '1',
    name: 'Music',
    status: 'Active'
  },
  {
    _id: '2',
    name: 'Visual Art',
    status: 'Inactive'
  },
  {
    _id: '3',
    name: 'Photo',
    status: 'Inactive'
  },
  {
    _id: '4',
    name: 'Video',
    status: 'Inactive'
  }
]);

  // useEffect(() => {
  //   const getContracts = async () => {
  //     const { data } = await getContractTypes();
  //     setIndustries(data);
  //   };

  //   getContracts();
  // }, []);

  return (
    <>
      <Heading textAlign="center" mb="32px">
        What industry are you interested in?
      </Heading>
      {industries.map((industry) => (
        <Flex
          data-heap-id={`industry.${industry.name}`}
          key={industry._id}
          mb="24px"
          py="20px"
          cursor="pointer"
          onClick={() => {
            if (industry.status === 'Active') {
              onIndustrySelected(industry._id);
            }
          }}
          w={{ base: '100%', lg: '350px' }}
          borderRadius="6px"
          border="1px solid"
          borderColor="brand.primary"
          justifyContent="center"
          color="brand.primary"
          position="relative"
          _hover={{
            bg: industry.status === 'Active' ? 'brand.primary' : 'none',
            color: industry.status === 'Active' ? 'brand.secondary1' : 'brand.primary'
          }}
        >
          {industry.name}
          <Box
            hidden={industry.status === 'Active'}
            as="span"
            position="absolute"
            marginX="auto"
            bottom="-15px"
            p="8px"
            bg="brand.primary"
            color="brand.secondary1"
            borderRadius="6px"
            fontSize="12px"
          >
            Coming Soon
          </Box>
        </Flex>
      ))}
    </>
  );
};
