import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  BoxProps,
  Image,
  useColorMode
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { userAtom } from 'src/atoms';
import { Contract } from 'src/interfaces';

export interface VieContractProps extends BoxProps {
  contract: Contract;
  lightUrls: string[];
  darkUrls: string[];
}

export const ViewContract: React.FC<VieContractProps> = ({
  contract,
  lightUrls,
  darkUrls,
  ...props
}) => {
  const titleBg = useColorModeValue('#FFFAF3', 'rgba(0, 0, 0, 0.25)');
  const mainBg = useColorModeValue(
    'linear-gradient(#FFEED3, #EBDCFF)',
    'brand.secondary2'
  );
  const { colorMode } = useColorMode();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [user] = useAtom(userAtom);

  useEffect(() => {
    if (colorMode === 'light') {
      setImageUrls(lightUrls);
    } else {
      setImageUrls(darkUrls);
    }
  }, [colorMode, darkUrls, lightUrls]);

  return (
    <Box
      {...props}
      h="100%"
      overflow="hidden"
      borderRadius="5px"
      paddingBottom="10px"
    >
      <Flex
        justifyContent="center"
        bg={titleBg}
        paddingY="17px"
        borderTopRadius="5px"
      >
        {((user?.email === contract.initiatorEmail &&
          !contract.initiatorSignedAt) ||
          (user?.email === contract.receiverEmail &&
            !contract.receiverSignedAt)) && (
          <Text
            textAlign="center"
            fontSize="20px"
            fontWeight="600"
            lineHeight="24px"
          >
            Please review your contract carefully before signing it
          </Text>
        )}
      </Flex>
      <Box bg={mainBg} h="100%">
        <Flex
          height="95%"
          overflowY="auto"
          flexDirection="column"
          alignItems="center"
          paddingY="10px"
        >
          {imageUrls.map((url, index) => (
            <Image
              pointerEvents="none"
              src={url}
              alt={`page ${index}`}
              mb="10px"
              key={index}
              w={{ lg: '50%' }}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
