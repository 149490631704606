import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";
import { accordionTheme } from "./accordion";
import { buttonTheme } from "./button";
import { radioTheme } from "./radio";
import { switchTheme } from "./switch";

const theme = extendTheme(
  {
    config: {
      useSystemColorMode: false,
      initialColorMode: "dark",
    },
    colors: {
      brand: {
        primary: "#F48657",
        secondary1: "#FFFFFF",
        secondary2: "#040518",
        secondary3: "#E2E8F0"
      }
    },
    fonts: {
      body: "Poppins, sans-serif",
      heading: "Poppins-Thin, sans-serif",
    },
    components: { Radio: radioTheme, Accordion: accordionTheme, Switch: switchTheme, Button: buttonTheme},
  },
  withProse({
    baseStyle: {
      p: {
        lineHeight: "20px",
        fontSize: "14px",
        fontWeight: "400",
        marginTop: "0",
      },
      em: {
        fontStyle: "italic",
      },
      b: {
        fontWeight: "700",
      },
    },
  })
);

export default theme;
