import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const BackArrow: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 10 10" {...props}>
      <path
        d="M4.99995 9.80001L0.199951 5.00001L4.99995 0.200012L5.84955 1.04961L2.49995 4.40001H9.79995V5.60001H2.49995L5.84955 8.95041L4.99995 9.80001Z"
        fill="currentColor"
      />
    </Icon>
  );
};
