import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Clef: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 37 31" {...props}>
      <path
        d="M25.49 0.06612L10.49 4.06612C10.0702 4.17673 9.6977 4.42096 9.4289 4.76192C9.1601 5.10288 9.00955 5.52205 9 5.95612V21.4061H6.5C5.30653 21.4061 4.16193 21.8802 3.31802 22.7241C2.47411 23.5681 2 24.7126 2 25.9061C2 27.0996 2.47411 28.2442 3.31802 29.0881C4.16193 29.932 5.30653 30.4061 6.5 30.4061H7.5C8.60434 30.4051 9.66938 29.9962 10.4907 29.2579C11.312 28.5197 11.8317 27.5041 11.95 26.4061H12V11.4061L25 7.40612V17.4061H22.5C21.3065 17.4061 20.1619 17.8802 19.318 18.7241C18.4741 19.5681 18 20.7126 18 21.9061C18 23.0996 18.4741 24.2442 19.318 25.0881C20.1619 25.932 21.3065 26.4061 22.5 26.4061H23.5C24.0913 26.4074 24.6771 26.2919 25.2236 26.0663C25.7702 25.8406 26.2668 25.5092 26.6849 25.091C27.103 24.6729 27.4345 24.1763 27.6601 23.6298C27.8858 23.0832 28.0013 22.4974 28 21.9061V2.00612C28.0009 1.6995 27.9314 1.39676 27.7966 1.12131C27.6619 0.845866 27.4657 0.605082 27.2231 0.417569C26.9805 0.230056 26.698 0.100836 26.3975 0.0398968C26.097 -0.0210427 25.7865 -0.0120709 25.49 0.06612V0.06612Z"
        fill="currentColor"
      />
      <path
        d="M32.5 10.4062H35.5C35.8978 10.4062 36.2794 10.2482 36.5607 9.96691C36.842 9.6856 37 9.30407 37 8.90625C37 8.50843 36.842 8.12689 36.5607 7.84559C36.2794 7.56428 35.8978 7.40625 35.5 7.40625H32.5C32.1022 7.40625 31.7206 7.56428 31.4393 7.84559C31.158 8.12689 31 8.50843 31 8.90625C31 9.30407 31.158 9.6856 31.4393 9.96691C31.7206 10.2482 32.1022 10.4062 32.5 10.4062ZM4.5 13.4062H1.5C1.10218 13.4062 0.720644 13.5643 0.43934 13.8456C0.158035 14.1269 0 14.5084 0 14.9062C0 15.3041 0.158035 15.6856 0.43934 15.9669C0.720644 16.2482 1.10218 16.4062 1.5 16.4062H4.5C4.89782 16.4062 5.27936 16.2482 5.56066 15.9669C5.84196 15.6856 6 15.3041 6 14.9062C6 14.5084 5.84196 14.1269 5.56066 13.8456C5.27936 13.5643 4.89782 13.4062 4.5 13.4062ZM35.5 13.4062H32.5C32.1022 13.4062 31.7206 13.5643 31.4393 13.8456C31.158 14.1269 31 14.5084 31 14.9062C31 15.3041 31.158 15.6856 31.4393 15.9669C31.7206 16.2482 32.1022 16.4062 32.5 16.4062H35.5C35.8978 16.4062 36.2794 16.2482 36.5607 15.9669C36.842 15.6856 37 15.3041 37 14.9062C37 14.5084 36.842 14.1269 36.5607 13.8456C36.2794 13.5643 35.8978 13.4062 35.5 13.4062ZM4.5 7.40625H1.5C1.10218 7.40625 0.720644 7.56428 0.43934 7.84559C0.158035 8.12689 0 8.50843 0 8.90625C0 9.30407 0.158035 9.6856 0.43934 9.96691C0.720644 10.2482 1.10218 10.4062 1.5 10.4062H4.5C4.89782 10.4062 5.27936 10.2482 5.56066 9.96691C5.84196 9.6856 6 9.30407 6 8.90625C6 8.50843 5.84196 8.12689 5.56066 7.84559C5.27936 7.56428 4.89782 7.40625 4.5 7.40625Z"
        fill="currentColor"
      />
    </Icon>
  );
};
