import {
  chakra,
  FlexProps,
  Flex,
  Box,
  Text,
  Heading,
  useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import ChartaAccented from './ChartaAccented';

export interface LoadScreenProps extends FlexProps {
  label?: string;
}

export const LoadScreen: React.FC<LoadScreenProps> = ({ label, ...props }) => {
  const bg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );

  return (
    <Flex
      width="100vw"
      height="100vh"
      background={bg}
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDir="column" alignItems="center">
        <Box w={{ base: '92px', lg: '200px' }} h={{ base: '24px', lg: '50px' }}>
          <ChartaAccented />
        </Box>
        <Heading fontSize="24px" mt="16px">
          {label || 'Loading...'}
        </Heading>
      </Flex>
    </Flex>
  );
};
