import React from "react";
import { ButtonProps, Button, useColorModeValue } from "@chakra-ui/react";

import { BackArrow } from "../icons/BackArrow";

export interface ChartaBackProps extends ButtonProps {
  label?: string;
}

export const ChartaBack: React.FC<ChartaBackProps> = ({
  onClick,
  label,
  ...props
}) => {
  const color = useColorModeValue("#6B30BA", "brand.primary");
  return (
    <Button
      variant="ghost"
      fontSize="16px"
      lineHeight="19.2px"
      fontWeight="600px"
      onClick={onClick}
      leftIcon={<BackArrow h="10px" w="10px" />}
      color={color}
      {...props}
    >
      {label ? label : "Back"}
    </Button>
  );
};
