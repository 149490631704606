import api from "../axios/axios";

export const getCurrentUser = () => {
  return api.get("/auth/current");
};

export const getUserProfile = () => {
  return api.get("/user/profile");
};

export const updateUserProfile = (data: any) => {
  return api.put("/user/profile", { ...data });
};

export const saveUserProfileImage = (image: string) => {
  return api.put("/user/profile-image", { image });
};
