import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const onRequest = (config: any): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: any): Promise<AxiosError> => {
  if (error.response) {
    // Access Token was expired
    //debugger;
    if (
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      try {
        await axios.get(`${API_URL}/auth/refresh`, {
          withCredentials: true,
        });
        if (
          error?.config?.url !== null &&
          error?.config?.url !== "/auth/login" &&
          error?.config?.url !== "/auth/register" &&
          error?.config?.url !== "/auth/refresh"
        ) {
          return await axios(error.config);
        }
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
