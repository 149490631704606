import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { switchAnatomy } from "@chakra-ui/anatomy";
import { mode } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  return {
    track: {
      background: mode("#C5C2C2", "#0C0C28")(props)
    },
    thumb: {
      background: mode(
        "linear-gradient(#8E30BA, #4B1495)",
        "linear-gradient(#F4B557, #F48657, #FD4767)"
      )(props),
    },
  };
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
