import { chakra, FlexProps, Flex, Box, Text, Link } from '@chakra-ui/react';
import React from 'react';
import ChartaAccented from './ChartaAccented';

export type FooterVariantType = 'default' | 'transparent';
export interface FooterProps extends FlexProps {
  variant?: FooterVariantType;
}

export const Footer: React.FC<FooterProps> = ({
  variant = 'default',
  ...props
}) => {
  return (
    <chakra.footer
      h="77px"
      py="26px"
      px="32px"
      background={variant === 'transparent' ? 'transparent' : '#1E2029'}
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box
          w={{ base: '45px', md: '91.75px' }}
          h={{ base: '12px', md: '24px' }}
        >
          <ChartaAccented />
        </Box>
        <Box>
          <Link
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            color="brand.primary"
            href="http://app.getcharta.com/"
            isExternal
          >
            Legacy Accounts
          </Link>
          <Text fontSize="16px" fontWeight="400" lineHeight="24px">
            &copy; 2023 Charta. All rights reserved.
          </Text>
        </Box>
      </Flex>
    </chakra.footer>
  );
};
